import {
  DomainHUBLicense,
  DomainLicense,
  getFullUrlByLicense,
  getHubHomeLinkByLicense
} from '@/utils/multiDomains';
import {
  StackProps,
  Stack,
  Img,
  Text,
  VStack,
  Button,
  HStack
} from '@chakra-ui/react';
import Link from 'next/link';
import SmallArticle from './SmallArticle';
import { useAppSettings } from '@/context/AppSettings';

export type Article = {
  topTitle: string;
  buttonTitle?: string;
  buttonBackgroundColor?: string;
  redirectionUrl: string;
  imageLeft?: boolean;
  imageUrl: string;
  title: string;
  body: string;
  smallArticles: {
    title: string;
    body: string;
    imageUrl: string;
  }[];
};

export type ArticleProps = StackProps & {
  article: Article;
  locale: string;
};

const Article = ({ article, locale, ...props }: ArticleProps) => {
  const appSettings = useAppSettings();
  return (
    <>
      <Stack
        flexDirection={['column', 'column', 'row']}
        width="100%"
        justifyContent="center"
        alignItems={'center'}
        {...props}
      >
        <Img
          maxWidth={['100%', '100%', '30rem']}
          // height="33.5rem"
          borderRadius="2rem"
          src={article.imageUrl}
          loading="lazy"
          order={article.imageLeft ? 0 : [0, 0, 0, 1]}
          margin={[
            0,
            0,
            '0 3rem 0 0',
            article.imageLeft ? '0 4rem 0 0' : '0 0 0 4rem'
          ]}
          height={['auto', 'auto', '30rem']}
          objectFit="cover"
        />
        <VStack
          as="article"
          align="flex-start"
          paddingY="1rem"
          order={article.imageLeft ? 1 : 0}
          maxWidth="25rem"
        >
          <Text
            fontSize={{ base: '0.75rem', md: '0.825rem' }}
            marginTop={{ base: '2.69rem !important', md: '0 !important' }}
            marginBottom="0.25rem !important"
            padding="0"
            textTransform="uppercase"
            color="buttonPrimary"
          >
            {article.topTitle}
          </Text>
          <Text
            textStyle="h2"
            as="h2"
            fontSize={{ base: '2rem', md: '3rem' }}
            fontWeight="500"
            lineHeight="3.25rem"
            marginBottom="0.75rem !important"
          >
            {article.title}
          </Text>
          <Text
            fontSize={{ base: '0.9375rem', md: '1.125rem' }}
            color="whiteAlpha.700"
            fontWeight="400"
            marginTop="0 !important"
            lineHeight="1.4375rem"
            marginBottom="1rem !important"
          >
            {article.body}
          </Text>
          {!!article?.redirectionUrl ? (
            <Link
              href={getHubHomeLinkByLicense({
                license: article.redirectionUrl as keyof DomainHUBLicense,
                locale,
                appSettingsFeatures: appSettings?.features
              })}
              passHref
            >
              <Button
                as={'a'}
                backgroundColor={article.buttonBackgroundColor}
                size="lg"
                _hover={{
                  background: `${article.buttonBackgroundColor}`
                }}
              >
                {article.buttonTitle}
              </Button>
            </Link>
          ) : null}
        </VStack>
      </Stack>
      <HStack
        spacing="2rem"
        width="100%"
        overflowX="scroll"
        alignSelf={['flex-start', 'flex-start', 'flex-start', 'center']}
        paddingX={['1rem', '1rem', '1rem', '0']}
        className="no-scrollbar"
        alignItems={'start'}
      >
        {article?.smallArticles.length ? (
          <>
            {article.smallArticles.map((article, index) => (
              <SmallArticle
                key={index}
                imageUrl={article.imageUrl}
                title={article.title}
                body={article.body}
              />
            ))}
          </>
        ) : null}
      </HStack>
    </>
  );
};

export default Article;
